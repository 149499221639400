export default {
  name: 'AccountAutoFill',

  props: {
    account: {
      type: Object,
      required: true,
    },
  },

  mounted() {
    this.setInputValue('#emailAddress', this.account.email_address);
    this.setInputValue(
      '#customerNavn',
      `${this.account.first_name} ${this.account.last_name ? this.account.last_name : ''}`,
    );
    this.setInputValue('#mobileNumber', this.account.mobile_phone_number);
  },
  render() {
    return this.$scopedSlots.default({});
  },
  methods: {
    setInputValue(selector, value) {
      if ($(selector).length && !$(selector).val()) {
        $(selector)
          .val(value)
          .blur();
      }
    },
  },
};
