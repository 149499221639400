<template>
  <AccountContainer ref="accountContainer" v-slot="{ account }" autoFill>
    <AccountAutoFill v-if="autofill && account" :account="account">
      <UserCard
        v-if="account"
        :name="`${account.first_name} ${account.last_name}`"
        :email="account.email_address"
        :clickable="clickable"
        :darkmode="darkmode"
      />
    </AccountAutoFill>
    <UserCard
      v-else-if="account"
      :name="`${account.first_name} ${account.last_name}`"
      :email="account.email_address"
      :clickable="clickable"
      :darkmode="darkmode"
    />
  </AccountContainer>
</template>

<script>
import AccountContainer from '@containers/AccountContainer';
import AccountAutoFill from './components/AccountAutoFill';
import UserCard from '@scenes/SelfserviceScene/components/UserCard';

export default {
  name: 'AccountCard',
  components: {
    AccountContainer,
    UserCard,
    AccountAutoFill,
  },
  props: {
    clickable: {
      type: Boolean,
      default: false,
    },
    darkmode: {
      type: Boolean,
      default: false,
    },
    autofill: {
      // Used here thansen.dk/fyrvaerkeri/checkout/ to fill out input fields.
      // TODO: Remove the feature when it hopefully becomes obsolete
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
